<template>
  <div class="content-list-content">
    <div class="content-list-table">
      <div class="mb-2 d-flex justify-content-between mb-5">
        <div class="w-100">
          <button
            v-on:click="gotoDashboard()"
            class="float-right button-back-dashboard"
          >
            戻る
          </button>
        </div>
      </div>
      <div v-if="listMenu && listMenu.length > 0">
        <div
          class="list-option mb-2 d-flex justify-content-center"
          v-for="(value, index) in listMenu"
          :key="index"
        >
          <div class="once-option">
            <router-link
              v-if="value.route"
              :to="{
                name: $route.params.shopId ? value.route : value.route_domain,
              }"
              class=""
            >
              <button class="float-right button-option-setting">
                <span v-html="value.text"></span>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "globalSetting",
  components: {},
  data() {
    return {
      listMenu: [
        {
          text: "ヘッダーメニュー管理",
          route: "headerSetting",
          route_domain: "headerSetting domain",
        },
        {
          text: "フッターメニュー管理",
          route: "footerSetting",
          route_domain: "footerSetting domain",
        },
        {
          text: "ボトムナビゲーション管理",
          route: "bottomSetting",
          route_domain: "bottomSetting domain",
        },
      ],
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "DashboardAdminBox" });
      } else {
        this.$router.push({ name: "DashboardAdminBox domain" });
      }
    },
  },
};
</script>
